import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { legacyBreakpoints } from '../utils/media'
import ConditionalWrapper from './ConditionalWrapper'
import CTA from './CTA'
import Wrapper from './Wrapper'

const backgroundImageStyles = css`
  && {
    position: absolute;
    inset: 0;
    z-index: -1;
  }
`
const darkTypography = css`
  h1,
  h2,
  h3,
  span,
  li {
    color: var(--color-typography-dark);
  }

  p {
    color: var(--color-typography-medium);
  }
`

const lightTypography = css`
  h1,
  h2,
  h3,
  span,
  li,
  p {
    color: var(--color-typography-light) !important;
  }
`

const whiteTheme = css`
  ${darkTypography};

  ${({ $primaryButtons }) =>
    !$primaryButtons &&
    css`
      ${CTA} {
        background-color: transparent;
        color: var(--color-primary);
        border: 1px solid;
      }
    `}
`

const lightBlueTheme = css`
  background-color: var(--color-background-light);

  ${darkTypography};

  svg {
    fill: var(--color-primary);

    g {
      stroke: var(--color-primary);
    }
  }

  ${({ $primaryButtons }) =>
    !$primaryButtons &&
    css`
      ${CTA} {
        background-color: var(--color-background-primary);
        color: var(--color-typography-on-primary);
      }
    `}
`

const blueTheme = css`
  background-color: var(--color-primary);

  ${lightTypography};

  ${({ $primaryButtons }) =>
    !$primaryButtons &&
    css`
      ${CTA} {
        background-color: var(--color-typography-light);
        color: var(--color-primary);
      }
    `}
`

const darkTheme = css`
  ${lightTypography};

  p {
    opacity: 0.5;
  }

  ${({ $primaryButtons }) =>
    !$primaryButtons &&
    css`
      ${CTA} {
        background-color: transparent;
        color: var(--color-typography-light);
        border: 1px solid;
        margin-bottom: 0 !important;

        &:hover {
          background-color: var(--color-typography-light);
          color: var(--color-primary);
          border: 1px solid;
        }
      }
    `}
`

const themeStyles = {
  Blue: blueTheme,
  White: whiteTheme,
  'Light Blue': lightBlueTheme,
  'Dark Background Image': darkTheme,
}

const containerStyles = css`
  padding: var(--spacing-lg) 0;
  overflow: hidden;
  text-align: ${({ $align }) => $align};
  position: relative;
  background-position: center;
  background-size: cover;

  ${({ $bgColor }) => themeStyles[$bgColor]}

  ${Wrapper} {
    > h2 {
      margin-bottom: ${({ $hasDescription }) => ($hasDescription ? '20px;' : '30px;')};
    }

    > p {
      max-width: 800px;
      margin-right: auto;
      margin-left: auto;
      font-size: 20px !important;
      line-height: 30px !important;
      margin-bottom: ${({ $hasComponent }) => ($hasComponent ? '40px !important' : 0)};
    }

    @media screen and (max-width: ${legacyBreakpoints.mobile}) {
      > h2 {
        margin-bottom: 10px;
        max-width: 90%;
        margin-right: auto;
        margin-left: auto;
      }

      > p {
        font-size: 16px !important;
        line-height: 24px !important;
        max-width: 90%;
      }
    }
  }

  ${CTA} {
    margin: 0 auto;
  }
`

const Container = styled.div`
  ${({ $hasBackgroundImage }) => $hasBackgroundImage && containerStyles}
`

const Section = ({ align, backgroundImage, bgColor, hasComponent, hasDescription, primaryButtons, ...rest }) => {
  const hasBackgroundImage = Boolean(backgroundImage)
  const styleProps = {
    $align: align,
    $hasBackgroundImage: hasBackgroundImage,
    $bgColor: bgColor,
    $hasComponent: hasComponent,
    $hasDescription: hasDescription,
    $primaryButtons: primaryButtons,
  }
  const containerProps = { ...styleProps, ...rest, $hasBackgroundImage: !hasBackgroundImage }

  return (
    <ConditionalWrapper
      condition={hasBackgroundImage}
      wrapper={(children) => <Container {...styleProps}>{children}</Container>}>
      <Container {...containerProps} />
      {hasBackgroundImage && (
        <GatsbyImage
          image={backgroundImage}
          alt=""
          css={backgroundImageStyles}
        />
      )}
    </ConditionalWrapper>
  )
}

export default Section
