import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import CTA from './CTA'

const BackgroundImages = styled.div`
  padding: 0 30px;

  p {
    font-size: 18px;
    line-height: 28px;
  }

  h2,
  p,
  ${CTA} {
    position: relative;
    z-index: 3;
  }

  img {
    margin-bottom: 0;
  }

  ${CTA} {
    margin-bottom: 50px;
  }
`

const ImageContainer = styled.div`
  max-width: 680px;
  margin: 0 auto;
`

/**
 * @typedef {object} Props
 * @prop {object} cta
 * @prop {import("gatsby-image").FluidObject=} image
 * @prop {string=} imageAlt
 */

/**
 * @type {React.FC<Props>}
 */
const PageSectionMainCTA = ({ cta, image, imageAlt }) => {
  return (
    <BackgroundImages>
      <CTA
        style={{ marginBottom: '50px' }}
        to={cta}
        $primary>
        {cta.text.value}
      </CTA>
      {image && (
        <ImageContainer>
          <GatsbyImage
            image={getImage(image.localFile)}
            alt={imageAlt}
          />
        </ImageContainer>
      )}
    </BackgroundImages>
  )
}

export default PageSectionMainCTA
