import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import CTA from '../components/CTA'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import MarkdownContent from '../components/MarkdownContent'
import PageSectionMainCTA from '../components/PageSectionMainCTA'
import Section from '../components/Section'
import Wrapper from '../components/Wrapper'
import useSignUpLink from '../hooks/queries/useSignUpLink'
import { useIntl } from '../utils/IntlContext'
import { formatFullName } from '../utils/locales'
import media from '../utils/media'

export const query = graphql`
  query SuccessStory($contentful_id: String!, $locale: String!) {
    contentfulSuccessStory(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      node_locale
      first_name
      last_name
      childContentfulSuccessStoryBioTextNode {
        childMarkdownRemark {
          htmlAst
          images {
            contentful_id
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 760)
              }
              publicURL
              extension
            }
          }
        }
      }
      photo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 378)
          }
        }
      }
      quote {
        quote
      }
    }
    mainCTAImage: contentfulAsset(contentful_id: { eq: "6o0ezY87ZHoDZy5cvvPaNZ" }, node_locale: { eq: "en-US" }) {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  }
`

const StoryHeaderContainer = styled.div`
  background-color: var(--color-background-light);
`

const StoryWrapper = styled(Wrapper)`
  padding: calc(var(--spacing-lg) + var(--nav-header-height)) var(--spacing-md) var(--spacing-lg);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacing-lg);
`

const StoryHeaderContent = styled.div`
  flex: 1 1 calc(60% - var(--spacing-lg) / 2);
  min-width: 100%;

  ${media.sm`
    min-width: unset;
  `}

  p {
    font-style: italic;
    font-size: 20px;
    margin-bottom: var(--spacing-xsm);
  }

  p:last-of-type {
    margin-bottom: var(--spacing-md);
  }
`

const SuccessStoryImage = styled(GatsbyImage)`
  max-width: 378px;
  flex: 1 1 calc(40% - var(--spacing-lg) / 2);
`

const StoryPage = ({
  pageContext: { translatedPages },
  data: {
    contentfulSuccessStory: {
      first_name,
      last_name,
      childContentfulSuccessStoryBioTextNode: {
        childMarkdownRemark: { htmlAst, images },
      },
      photo,
      quote: { quote },
    },
    mainCTAImage,
  },
}) => {
  const { locale, t } = useIntl()
  const signupLink = useSignUpLink(locale)

  const fullName = formatFullName(first_name, last_name)

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <StoryHeaderContainer>
        <StoryWrapper>
          <SuccessStoryImage
            image={getImage(photo.localFile)}
            alt={fullName}
            loading="eager"
          />
          <StoryHeaderContent>
            <h1>Meet {fullName}</h1>
            <p>&quot; {quote} &quot;</p>
            <CTA
              to={signupLink}
              $primary>
              {t('signup')}
            </CTA>
          </StoryHeaderContent>
        </StoryWrapper>
      </StoryHeaderContainer>
      <Wrapper>
        <MarkdownContent
          el={htmlAst}
          images={images}
        />
      </Wrapper>
      <Section
        key="story-maincta-section"
        align="center"
        bgColor="white"
        hasComponent>
        <Wrapper>
          <h2>{t('next_job_faster')}</h2>
          <PageSectionMainCTA
            key="story-maincta"
            theme="white"
            cta={signupLink}
            image={mainCTAImage}
          />
        </Wrapper>
      </Section>
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulSuccessStory: {
      first_name,
      last_name,
      quote: { quote },
    },
  },
}) => {
  const fullName = formatFullName(first_name, last_name)

  return (
    <GatsbyHead
      pageTitle={`${fullName} Success Story`}
      metaDescription={quote}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default StoryPage
